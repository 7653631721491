import {http} from '@/http/axios.js'

// 列表
export function getListAPI(data) {
    return http({
        url: `/api/manage/user/list`,
        method: 'POST',
        data
    })
}


export function getDetailAPI(id) {
    return http({
        url: `/api/manage/user/detail`,
        method: 'post',
        data: {id}
    })
}


export function getAgentEmployeeTreeAPI() {
    return http({
        url: `/api/manage/agent/tree`,
        method: 'post',
    })
}

export function rechargeUserOrderAPI(data) {
    return http({
        url: `/api/manage/order/rechargeUserOrder`,
        method: 'post',
        data
    })
}

export function distributeEmployeeAPI(data) {
    return http({
        url: `/api/manage/user/distributeEmployee`,
        method: 'post',
        data
    })
}

export function getUserProductInfo(uid) {
    return http({
        url: `/api/manage/user/getUserProductInfo`,
        method: 'post',
        data: {uid}
    })
}


export function getChildUser(pid) {
    return http({
        url: `/api/manage/user/getChildList`,
        method: 'post',
        data: {pid}
    })
}

export function getOrderListAPI(uid) {
    return http({
        url: `/api/manage/order/list`,
        method: 'post',
        data: {uid, pay_status: 20, order_type: 10}
    })
}

export function getOrderGiveListAPI(uid) {
    return http({
        url: `/api/manage/order/list`,
        method: 'post',
        data: {uid, pay_status: 20, order_type: 20}
    })
}

export function giveExpireTimeAPI(uid, day) {
    return http({
        url: `/api/manage/order/giveExpireTime`,
        method: 'post',
        data: {uid, day}
    })
}

export function getBrowsingHistoryListAPI(data) {
    return http({
        url: `/api/manage/user/biaoxun/getLookList`,
        method: 'post',
        data
    })
}


export function getVipProductListAPI(data) {
    return http({
        url: `/api/manage/vipProduct/list`,
        method: 'post',
        data
    })
}

export function rechargeByPlatformAPI(data) {
    return http({
        url: `/api/manage/order/rechargeByPlatform`,
        method: 'post',
        data
    })
}
